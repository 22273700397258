import { AuthActions, loginRequest, logout } from './Auth';
import { BlogActions, getBlogs, handleDeleteBlog } from './Blog';
import { ChoiceActions, getChoices, handleDeleteChoice } from './Choice';
import {
	CommentActions,
	getComments,
	handleChangeCommentPagination,
	handleDeleteComment,
} from './Comment';
import {
	ContactActions,
	getContacts,
	handleChangeContactPagination,
	handleDeleteContact,
} from './Contact';
import {
	CourseActions,
	getCourses,
	handleChangeCoursePagination,
	handleDeleteCourse,
} from './Course';
import {
	EnrollmentActions,
	getEnrollments,
	handleChangeEnrollmentPagination,
	handleDeleteEnrollment,
} from './Enrollment';
import { GeneralActions, getGeneral } from './General';
import { getLessons, handleDeleteLesson, LessonActions } from './Lesson';
import {
	getMediaContents,
	handleChangeMediaContentPagination,
	handleDeleteMediaContent,
	MediaContentActions,
} from './MediaContent';
import {
	getProducts,
	handleChangeProductPagination,
	handleDeleteProduct,
	ProductActions,
} from './Product';
import { getMyProfile, ProfileActions } from './Profile';
import { getPrograms, handleDeleteProgram, ProgramActions } from './Program';
import {
	getProjects,
	handleChangeProjectPagination,
	handleDeleteProject,
	ProjectActions,
} from './Project';
import {
	getQuestions,
	handleDeleteQuestion,
	QuestionActions,
} from './Question';
import {
	getReviews,
	handleChangeReviewPagination,
	handleDeleteReview,
	ReviewActions,
} from './Review';
import {
	getSubmissions,
	handleDeleteSubmission,
	SubmissionActions,
} from './Submission';
import {
	getUsers,
	handleChangePagination,
	handleDeleteUser,
	UserActions,
} from './User';

import { all, takeEvery, takeLatest } from 'redux-saga/effects';
/* ------------- Connect Types To Sagas ------------- */
export default function* rootSaga() {
	yield all([
		// auth
		takeEvery(AuthActions.logoutRequest.type, logout),
		takeEvery(AuthActions.loginRequest.type, loginRequest),
		// profile
		takeEvery(ProfileActions.getProfileRequest.type, getMyProfile),
		// General
		takeEvery(GeneralActions.getGeneralRequest.type, getGeneral),
		// User
		takeEvery(
			[
				UserActions.getUsersRequest.type,
				UserActions.updateUserSuccess.type,
			],
			getUsers
		),
		takeLatest(UserActions.changePagination.type, handleChangePagination),
		takeLatest(UserActions.deleteUserRequest.type, handleDeleteUser),
		// Project
		takeEvery([ProjectActions.getProjectsRequest.type], getProjects),
		takeLatest(
			ProjectActions.changeProjectPagination.type,
			handleChangeProjectPagination
		),
		takeLatest(
			ProjectActions.deleteProjectRequest.type,
			handleDeleteProject
		),
		// Submission
		takeEvery(
			[
				SubmissionActions.getSubmissionsRequest.type,
				SubmissionActions.updateSubmissionSuccess.type,
			],
			getSubmissions
		),
		takeLatest(
			SubmissionActions.deleteSubmissionRequest.type,
			handleDeleteSubmission
		),
		// Product
		takeEvery(
			[
				ProductActions.getProductsRequest.type,
				ProductActions.updateProductSuccess.type,
			],
			getProducts
		),
		takeLatest(
			ProductActions.changeProductPagination.type,
			handleChangeProductPagination
		),
		takeLatest(
			ProductActions.deleteProductRequest.type,
			handleDeleteProduct
		),
		// Course
		takeEvery(
			[
				CourseActions.getCoursesRequest.type,
				CourseActions.updateCourseSuccess.type,
			],
			getCourses
		),
		takeLatest(
			CourseActions.changeCoursePagination.type,
			handleChangeCoursePagination
		),
		takeLatest(CourseActions.deleteCourseRequest.type, handleDeleteCourse),

		// Contact
		takeEvery(
			[
				ContactActions.getContactsRequest.type,
				ContactActions.updateContactSuccess.type,
			],
			getContacts
		),
		takeLatest(
			ContactActions.changeContactPagination.type,
			handleChangeContactPagination
		),
		takeLatest(
			ContactActions.deleteContactRequest.type,
			handleDeleteContact
		),

		// MediaContent
		takeEvery(
			[
				MediaContentActions.getMediaContentsRequest.type,
				MediaContentActions.updateMediaContentSuccess.type,
			],
			getMediaContents
		),
		takeLatest(
			MediaContentActions.changeMediaContentPagination.type,
			handleChangeMediaContentPagination
		),
		takeLatest(
			MediaContentActions.deleteMediaContentRequest.type,
			handleDeleteMediaContent
		),

		// Enrollment
		takeEvery(
			[
				EnrollmentActions.getEnrollmentsRequest.type,
				EnrollmentActions.updateEnrollmentSuccess.type,
			],
			getEnrollments
		),
		takeLatest(
			EnrollmentActions.changeEnrollmentPagination.type,
			handleChangeEnrollmentPagination
		),
		takeLatest(
			EnrollmentActions.deleteEnrollmentRequest.type,
			handleDeleteEnrollment
		),

		// Comment
		takeEvery(
			[
				CommentActions.getCommentsRequest.type,
				CommentActions.updateCommentSuccess.type,
			],
			getComments
		),
		takeLatest(
			CommentActions.changeCommentPagination.type,
			handleChangeCommentPagination
		),
		takeLatest(
			CommentActions.deleteCommentRequest.type,
			handleDeleteComment
		),

		// Program
		takeEvery(
			[
				ProgramActions.getProgramsRequest.type,
				ProgramActions.updateProgramSuccess.type,
			],
			getPrograms
		),
		takeLatest(
			ProgramActions.deleteProgramRequest.type,
			handleDeleteProgram
		),

		// Question
		takeEvery(
			[
				QuestionActions.getQuestionsRequest.type,
				QuestionActions.updateQuestionSuccess.type,
			],
			getQuestions
		),
		takeLatest(
			QuestionActions.deleteQuestionRequest.type,
			handleDeleteQuestion
		),
		// Lesson
		takeEvery(
			[
				LessonActions.getLessonsRequest.type,
				LessonActions.updateLessonSuccess.type,
			],
			getLessons
		),
		takeLatest(LessonActions.deleteLessonRequest.type, handleDeleteLesson),

		// Choice
		takeEvery(
			[
				ChoiceActions.getChoicesRequest.type,
				ChoiceActions.updateChoiceSuccess.type,
			],
			getChoices
		),
		takeLatest(ChoiceActions.deleteChoiceRequest.type, handleDeleteChoice),

		// Review
		takeEvery(
			[
				ReviewActions.getReviewsRequest.type,
				ReviewActions.updateReviewSuccess.type,
			],
			getReviews
		),
		takeLatest(
			ReviewActions.changeReviewPagination.type,
			handleChangeReviewPagination
		),
		takeLatest(ReviewActions.deleteReviewRequest.type, handleDeleteReview),
		// Blog
		takeEvery(BlogActions.getBlogsRequest.type, getBlogs),
		takeLatest(BlogActions.deleteBlogRequest.type, handleDeleteBlog),
	]);
}
