import { isSuccess, ServiceAdminApi } from '@/services';
import { ProjectActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getProjects(
	action: PayloadAction<{
		pageSize: number;
		current: number;
	}>
): any {
	try {
		const { pageSize = 10, current = 1 } = action.payload;
		const res = yield ServiceAdminApi.getProjects({
			limit: pageSize,
			offset: (current - 1) * pageSize,
			order: ['index ASC', 'id DESC'],
			include: [
				{
					relation: 'mediaContents',
					scope: {
						limit: 1,
						order: ['id DESC'],
					},
				},
			],
			where: {
				branchId: 1, // 1 is code4change, other id is other project
			},
		});

		if (isSuccess(res)) {
			yield put(
				ProjectActions.getProjectsSuccess({
					projects: res?.data?.data,
					total: res?.data?.count,
				})
			);
		} else {
			yield all([put(ProjectActions.getProjectsFailure())]);
		}
	} catch (error) {
		yield all([put(ProjectActions.getProjectsFailure())]);
	}
}

export function* handleChangeProjectPagination(
	action: PayloadAction<{ pageSize: number; current: number }>
): any {
	const { pageSize, current } = action.payload;

	const res = yield call(ServiceAdminApi.getProjects, {
		limit: pageSize,
		offset: (current - 1) * pageSize,
		order: ['id DESC'],
		include: [
			{
				relation: 'mediaContents',
			},
		],
		where: {
			branchId: 1, // 1 is code4change, other id is other project
		},
	});

	if (isSuccess(res)) {
		yield put(
			ProjectActions.getProjectsSuccess({
				projects: res?.data?.data,
				total: res?.data?.count,
			})
		);
	} else {
		yield all([put(ProjectActions.getProjectsFailure())]);
	}
}

export function* handleDeleteProject(action: PayloadAction<number>): any {
	const res = yield call(ServiceAdminApi.deleteProject, action.payload);

	if (isSuccess(res)) {
		yield put(
			ProjectActions.getProjectsRequest({
				current: 1,
				pageSize: 10,
			})
		);
	} else {
		yield all([put(ProjectActions.getProjectsFailure())]);
	}
}
