import { NextRouter } from 'next/router';

import { ROUTERS } from '@/constant';
import {
	ServiceApi,
	setAdminApiAuthorization,
	setApiAuthorization,
} from '@/services';
import {
	deleteCookieAfterLogout,
	getAccessToken,
	isSuccess,
	setAuthCookie,
} from '@/utils/Api.util';
import { PayloadAction } from '@reduxjs/toolkit';

import { AuthActions } from '../Auth';
import { GeneralActions } from '../General';
import { ProfileActions } from './Profile.redux';

import { notification } from 'antd';
import { isNilOrEmpty } from 'ramda-adjunct';
import { all, delay, put } from 'redux-saga/effects';

export function* getMyProfile(action: PayloadAction<NextRouter>): any {
	try {
		yield put(GeneralActions.getGeneralRequest());
		const token = getAccessToken();

		if (isNilOrEmpty(token)) {
			notification.error({
				duration: 10,
				message:
					'Permission Denied. Please check your login information or try again later.',
			});
			yield put(ProfileActions.getProfileFailure());
			action.payload.replace(ROUTERS.HOME);
			return;
		}

		setApiAuthorization(token);

		const profile = yield ServiceApi.getUserMe();

		if (isSuccess(profile)) {
			setAdminApiAuthorization(token);
			setAuthCookie({ token: token });
			yield delay(1000);
			yield put(AuthActions.loginSuccess(token));
			yield put(ProfileActions.getProfileSuccess(profile?.data));
		} else {
			notification.error({
				message:
					'Authentication failed. Please check your login information or try again later.',
				duration: 10,
			});
			yield all([
				put(ProfileActions.getProfileFailure()),
				put(AuthActions.resetAuth()),
			]);
			deleteCookieAfterLogout();
			action.payload.replace(ROUTERS.HOME);
		}
	} catch (error) {
		yield all([
			put(ProfileActions.getProfileFailure()),
			put(AuthActions.resetAuth()),
		]);
		deleteCookieAfterLogout();
		action.payload.replace(ROUTERS.LOGIN);
	}
}
