import { isSuccess, ServiceAdminApi } from '@/services';
import { LessonActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getLessons(action: PayloadAction<number>): any {
	const programId = action.payload;

	try {
		const res = yield ServiceAdminApi.getLessons({
			where: {
				programId,
			},
			order: ['index ASC', 'id DESC'],
		});

		if (isSuccess(res)) {
			yield put(
				LessonActions.getLessonsSuccess({
					lessons: res?.data,
					programId,
				})
			);
		} else {
			yield all([put(LessonActions.getLessonsFailure(programId))]);
		}
	} catch (error) {
		yield all([put(LessonActions.getLessonsFailure(programId))]);
	}
}

export function* handleDeleteLesson(action: PayloadAction<number>): any {
	const programId = action.payload;

	try {
		const res = yield call(ServiceAdminApi.deleteLesson, action.payload);

		if (isSuccess(res)) {
			yield put(LessonActions.getLessonsRequest(programId));
		} else {
			yield put(LessonActions.getLessonsFailure(programId));
		}
	} catch (err) {
		yield put(LessonActions.getLessonsFailure(programId));
	}
}
