import { NextRouter } from 'next/router';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IAuthState = {
	fetching: false,
	token: null,
};

type IAuthState = {
	fetching: boolean;
	token: string | null;
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest: (
			state: IAuthState,
			action: PayloadAction<{
				email: string;
				password: string;
				router: NextRouter;
			}>
		) => {
			return {
				...state,
				fetching: true,
			};
		},
		loginSuccess: (state: IAuthState, action: PayloadAction<string>) => {
			return {
				...state,
				fetching: false,
				logged: true,
				token: action.payload,
			};
		},
		loginFailure: (state: IAuthState) => {
			return {
				...state,
				logged: false,
				fetching: false,
			};
		},
		logoutRequest: (state: IAuthState) => {
			return {
				...state,
				logged: false,
				token: null,
				fetching: false,
			};
		},
		resetAuth: (state: IAuthState) => {
			return {
				...state,
				...initialState,
			};
		},
	},
});

// Selectors
export const AuthSelector = {
	selectAuthState: (state: RootState) => ({
		...state.auth,
	}),
	selectToken: (state: RootState) => state.auth.token,
};

export const AuthActions = authSlice.actions;
export const AuthReducer = authSlice.reducer;
