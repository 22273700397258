export const ROUTERS = {
	HOME: '/',
	ABOUT_US: '/about-us',
	PROJECT: '/projects',
	PROJECT_DETAIL: '/projects/[slug]',
	COURSE: '/course',
	COURSE_DETAIL: '/course/[slug]',
	LESSON_DETAIL: '/course/[slug]/lesson',
	CONSTRUCTION: '/construction',
	CONTACT: '/contact',
	BLOG: '/blogs',
	SEARCH_BLOG: '/search-blog',
	BLOG_DETAIL: '/blogs/[slug]',
	PRODUCT: '/product',
	PRODUCT_DETAIL: '/product/[slug]',
	NEWS: '/news',
	NEWS_DETAIL: '/news/[slug]',
	LOGIN: '/',
	ADMIN_REVIEW: '/admin/review',
	ADMIN_USER: '/admin/user',
	ADMIN_USER_DETAIL: '/admin/user/detail/[userId]',
	ADMIN_DASHBOARD: '/admin/dashboard',
	ADMIN_PROJECT: '/admin/project',
	ADMIN_PRODUCT: '/admin/product',
	ADMIN_COURSE: '/admin/course',
	ADMIN_ENROLLMENT: '/admin/enrollment',
	ADMIN_COMMENT: '/admin/comment',
	ADMIN_CHILD_COMMENT: '/admin/comment/[commentId]/child-comments',
	ADMIN_COURSE_DETAIL: '/admin/course/detail/[courseId]',
	ADMIN_QUESTION: '/admin/course/detail/[courseId]/question/[programId]',
	ADMIN_BLOG: '/admin/blog',
	ADMIN_BLOG_CREATE: '/admin/blog/create',
	ADMIN_BLOG_EDIT: '/admin/blog/[blogId]/edit',
	ADMIN_EXPERT_BLOG: '/admin/expert/blog',
	ADMIN_EXPERT_BLOG_CREATE: '/admin/expert/blog/create',
	ADMIN_EXPERT_BLOG_EDIT: '/admin/expert/blog/[blogId]/edit',
	ADMIN_EXPERT_COURSE: '/admin/expert/course',
	ADMIN_COMMENT_BLOG: '/admin/blog/[blogId]/comments',
	ADMIN_CONTACT: '/admin/contact',
	ADMIN_MEDIA_CONTENT: '/admin/media-content',
	ADMIN_SUBMISSION: '/admin/submission',
};
