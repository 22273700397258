import { configureStore } from '@reduxjs/toolkit';

import { AuthReducer } from './Auth/Auth.redux';
import { BlogReducer } from './Blog';
import { ChoiceReducer } from './Choice';
import { CommentReducer } from './Comment';
import { ContactReducer } from './Contact';
import { CourseReducer } from './Course';
import { EnrollmentReducer } from './Enrollment';
import { GeneralReducer } from './General';
import { LessonReducer } from './Lesson';
import { MediaContentReducer } from './MediaContent';
import { ProductReducer } from './Product';
import { ProfileReducer } from './Profile';
import { ProgramReducer } from './Program';
import { ProjectReducer } from './Project';
import { QuestionReducer } from './Question';
import { ReviewReducer } from './Review';
import { persistConfig } from './Store.persist';
import rootSaga from './Store.saga';
import { SubmissionReducer } from './Submission';
import { UserReducer } from './User';

import { combineReducers } from 'redux';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

const rootReducer = combineReducers({
	auth: AuthReducer,
	profile: ProfileReducer,
	general: GeneralReducer,
	user: UserReducer,
	project: ProjectReducer,
	product: ProductReducer,
	comment: CommentReducer,
	contact: ContactReducer,
	course: CourseReducer,
	enrollment: EnrollmentReducer,
	program: ProgramReducer,
	question: QuestionReducer,
	choice: ChoiceReducer,
	submission: SubmissionReducer,
	lesson: LessonReducer,
	blog: BlogReducer,
	review: ReviewReducer,
	mediaContent: MediaContentReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}).concat(sagaMiddleware),
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persistor, store };
