import { isSuccess, ServiceAdminApi } from '@/services';
import { ContactActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getContacts(
    action: PayloadAction<{
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getContacts({
            limit: pageSize,
            offset: (current - 1) * pageSize,
        });

        if (isSuccess(res)) {
            yield put(
                ContactActions.getContactsSuccess({
                    contacts: res?.data?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(ContactActions.getContactsFailure())]);
        }
    } catch (error) {
        yield all([put(ContactActions.getContactsFailure())]);
    }
}

export function* handleChangeContactPagination(
    action: PayloadAction<{ pageSize: number; current: number }>
): any {
    const { pageSize, current } = action.payload;

    const res = yield call(ServiceAdminApi.getContacts, {
        limit: pageSize,
        offset: (current - 1) * pageSize,
    });

    if (isSuccess(res)) {
        yield put(
            ContactActions.getContactsSuccess({
                contacts: res?.data?.data,
                total: res?.data?.count,
            })
        );
    } else {
        yield all([put(ContactActions.getContactsFailure())]);
    }
}

export function* handleDeleteContact(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteContact, action.payload);

    if (isSuccess(res)) {
        yield put(ContactActions.getContactsRequest({
            current: 1,
            pageSize: 10,
        }));
    } else {
        yield all([put(ContactActions.getContactsFailure())]);
    }
}
