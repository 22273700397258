import { IQuestion } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IQuestionState = {
    fetching: false,
    questions: [],
    total: 0,
};
type IQuestionState = {
    fetching: boolean;
    questions: IQuestion[];
    total: number;
};
export const QuestionSelectors = {
    selectQuestion: (state: RootState) => state.question,
};

const QuestionSlice = createSlice({
    initialState,
    name: 'Question',
    reducers: {
        getQuestionsRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
        getQuestionsSuccess: (
            state,
            action: PayloadAction<{ questions: IQuestion[] }>
        ) => ({
            ...state,
            questions: action.payload.questions,
            total: action.payload.questions?.length,
            fetching: false,
        }),
        getQuestionsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        updateQuestionSuccess: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
        deleteQuestionRequest: (state, action: PayloadAction<{ id: number; programId: number }>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const QuestionActions = QuestionSlice.actions;
export const QuestionReducer = QuestionSlice.reducer;
