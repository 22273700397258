import { isSuccess, ServiceAdminApi, Where } from '@/services';
import { PayloadAction } from '@reduxjs/toolkit';

import { BlogActions, BlogSelectors } from './Blog.redux';

import { all, call, put, select } from 'redux-saga/effects';

export function* getBlogs(
	action?: PayloadAction<{
		where?: Where;
		pageSize: number;
		current: number;
	}>
): any {
	try {
		const pagination = yield select(BlogSelectors.selectPagination);
		const pageSize = action?.payload?.pageSize || pagination?.pageSize;
		const current = action?.payload?.current || pagination?.current;

		const res = yield ServiceAdminApi.getBlogs({
			limit: pageSize,
			offset: (current - 1) * pageSize,
			order: ['index ASC', 'id DESC'],
			include: [
				{
					relation: 'mediaContents',
					scope: {
						limit: 1,
						order: ['id DESC'],
					},
				},
			],
			where: {
				branchId: 1, // 1 is code4change, other id is other project
				...action?.payload?.where,
			},
		});

		if (isSuccess(res)) {
			yield put(
				BlogActions.getBlogsSuccess({
					blogs: res?.data?.data,
					total: res?.data?.count,
				})
			);
		} else {
			yield all([put(BlogActions.getBlogsFailure())]);
		}
	} catch (error) {
		yield all([put(BlogActions.getBlogsFailure())]);
	}
}

export function* handleDeleteBlog(
	action: PayloadAction<{ blogId: number; where?: Where }>
): any {
	const res = yield call(ServiceAdminApi.deleteBlog, action.payload.blogId);

	if (isSuccess(res)) {
		yield put(BlogActions.getBlogsRequest({}));
	} else {
		yield all([put(BlogActions.getBlogsFailure())]);
	}
}
