import { IContact } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IContactState = {
    fetching: false,
    contacts: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type IContactState = {
    fetching: boolean;
    contacts: IContact[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const ContactSelectors = {
    selectContact: (state: RootState) => state.contact,
    selectPagination: (state: RootState) => state.contact.pagination,
};

const ContactSlice = createSlice({
    initialState,
    name: 'Contact',
    reducers: {
        getContactsRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getContactsSuccess: (
            state,
            action: PayloadAction<{ contacts: IContact[]; total: number }>
        ) => ({
            ...state,
            contacts: action.payload.contacts,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getContactsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeContactPagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateContactSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteContactRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const ContactActions = ContactSlice.actions;
export const ContactReducer = ContactSlice.reducer;
