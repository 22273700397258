import { isSuccess, ServiceAdminApi } from '@/services';
import { QuestionActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getQuestions(action: PayloadAction<number>): any {
    try {
        const programId = action.payload;
        const res = yield ServiceAdminApi.getQuestions({
            where: {
                programId
            }
        });

        if (isSuccess(res)) {
            yield put(
                QuestionActions.getQuestionsSuccess({
                    questions: res?.data,
                })
            );
        } else {
            yield all([put(QuestionActions.getQuestionsFailure())]);
        }
    } catch (error) {
        yield all([put(QuestionActions.getQuestionsFailure())]);
    }
}

export function* handleDeleteQuestion(action: PayloadAction<{ id: number; programId: number }>): any {
    const res = yield call(ServiceAdminApi.deleteQuestion, action.payload.id);
    const {programId} = action.payload;
    if (isSuccess(res)) {
        yield put(QuestionActions.getQuestionsRequest(Number(programId)));
    } else {
        yield all([put(QuestionActions.getQuestionsFailure())]);
    }
}
