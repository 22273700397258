import { ILesson } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { pathOr } from 'ramda';

const initialState: ILessonState = {
	lessons: {},
};
type ILessonState = {
	lessons:
		| {
				[key: number]: {
					lessons: ILesson[];
					total: number;
					fetching: boolean;
				};
		  }
		| {};
};
export const LessonSelectors = {
	selectLesson: (programId: number) => (state: RootState) =>
		pathOr({}, ['lesson', 'lessons', programId], state),
};

const LessonSlice = createSlice({
	initialState,
	name: 'Lesson',
	reducers: {
		getLessonsRequest: (state, action: PayloadAction<number>) => ({
			...state,
			lessons: {
				...state.lessons,
				[action.payload]: {
					...pathOr({}, ['lessons', action.payload], state),
					fetching: false,
				},
			},
			fetching: true,
		}),
		getLessonsSuccess: (
			state,
			action: PayloadAction<{
				lessons: ILesson[];
				programId: number;
			}>
		) => ({
			...state,
			lessons: {
				...state.lessons,
				[action.payload.programId]: {
					lessons: action.payload.lessons,
					total: action.payload.lessons?.length,
					fetching: false,
				},
			},
		}),
		getLessonsFailure: (state, action: PayloadAction<number>) => ({
			...state,
			lessons: {
				...state.lessons,
				[action.payload]: {
					...pathOr({}, ['lessons', action.payload], state),
					fetching: false,
				},
			},
			fetching: false,
		}),
		updateLessonSuccess: (state, action: PayloadAction<number>) => ({
			...state,
		}),
		deleteLessonRequest: (state, action: PayloadAction<number>) => ({
			...state,
		}),
	},
});

export const LessonActions = LessonSlice.actions;
export const LessonReducer = LessonSlice.reducer;
