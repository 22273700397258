import { IReview } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IReviewState = {
    fetching: false,
    reviews: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type IReviewState = {
    fetching: boolean;
    reviews: IReview[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const ReviewSelectors = {
    selectReview: (state: RootState) => state.review,
    selectPagination: (state: RootState) => state.review.pagination,
};

const ReviewSlice = createSlice({
    initialState,
    name: 'Review',
    reducers: {
        getReviewsRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getReviewsSuccess: (
            state,
            action: PayloadAction<{ reviews: IReview[]; total: number }>
        ) => ({
            ...state,
            reviews: action.payload.reviews,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getReviewsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeReviewPagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateReviewSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteReviewRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const ReviewActions = ReviewSlice.actions;
export const ReviewReducer = ReviewSlice.reducer;
