import { IEnrollment } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IEnrollmentState = {
    fetching: false,
    enrollments: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type IEnrollmentState = {
    fetching: boolean;
    enrollments: IEnrollment[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const EnrollmentSelectors = {
    selectEnrollment: (state: RootState) => state.enrollment,
    selectPagination: (state: RootState) => state.enrollment.pagination,
};

const EnrollmentSlice = createSlice({
    initialState,
    name: 'Enrollment',
    reducers: {
        getEnrollmentsRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getEnrollmentsSuccess: (
            state,
            action: PayloadAction<{ enrollments: IEnrollment[]; total: number }>
        ) => ({
            ...state,
            enrollments: action.payload.enrollments,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getEnrollmentsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeEnrollmentPagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateEnrollmentSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteEnrollmentRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const EnrollmentActions = EnrollmentSlice.actions;
export const EnrollmentReducer = EnrollmentSlice.reducer;
