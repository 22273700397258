import {IComment, Where} from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: ICommentState = {
    fetching: false,
    comments: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type ICommentState = {
    fetching: boolean;
    comments: IComment[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const CommentSelectors = {
    selectComment: (state: RootState) => state.comment,
    selectPagination: (state: RootState) => state.comment.pagination,
};

const CommentSlice = createSlice({
    initialState,
    name: 'Comment',
    reducers: {
        getCommentsRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
            where?: Where<IComment>;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getCommentsSuccess: (
            state,
            action: PayloadAction<{ comments: IComment[]; total: number }>
        ) => ({
            ...state,
            comments: action.payload.comments,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getCommentsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeCommentPagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number; where?: Where<IComment>; }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateCommentSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteCommentRequest: (state, action: PayloadAction<{ commentId: number; where?: Where }>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const CommentActions = CommentSlice.actions;
export const CommentReducer = CommentSlice.reducer;
