import { isSuccess, ServiceAdminApi } from '@/services';
import { ReviewActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getReviews(
    action: PayloadAction<{
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getReviews({
            limit: pageSize,
            offset: (current - 1) * pageSize,
            order: ['id DESC'],
            where: {
                branchId: 1, // 1 is code4change, other id is other review
            },
        });

        if (isSuccess(res)) {
            yield put(
                ReviewActions.getReviewsSuccess({
                    reviews: res?.data?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(ReviewActions.getReviewsFailure())]);
        }
    } catch (error) {
        yield all([put(ReviewActions.getReviewsFailure())]);
    }
}

export function* handleChangeReviewPagination(
    action: PayloadAction<{ pageSize: number; current: number }>
): any {
    const { pageSize, current } = action.payload;

    const res = yield call(ServiceAdminApi.getReviews, {
        limit: pageSize,
        offset: (current - 1) * pageSize,
        order: ['id DESC'],
        include: [
            {
                relation: 'mediaContents',
            },
        ],
        where: {
            branchId: 1, // 1 is code4change, other id is other review
        },
    });

    if (isSuccess(res)) {
        yield put(
            ReviewActions.getReviewsSuccess({
                reviews: res?.data,
                total: res?.count,
            })
        );
    } else {
        yield all([put(ReviewActions.getReviewsFailure())]);
    }
}

export function* handleDeleteReview(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteReview, action.payload);

    if (isSuccess(res)) {
        yield put(ReviewActions.getReviewsRequest({
            current: 1,
            pageSize: 10,
        }));
    } else {
        yield all([put(ReviewActions.getReviewsFailure())]);
    }
}
