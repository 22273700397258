import { ISubmission } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: ISubmissionState = {
    fetching: false,
    submissions: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type ISubmissionState = {
    fetching: boolean;
    submissions: ISubmission[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const SubmissionSelectors = {
    selectSubmission: (state: RootState) => state.submission,
    selectPagination: (state: RootState) => state.submission.pagination,
};

const SubmissionSlice = createSlice({
    initialState,
    name: 'Submission',
    reducers: {
        getSubmissionsRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getSubmissionsSuccess: (
            state,
            action: PayloadAction<{ submissions: ISubmission[]; total: number }>
        ) => ({
            ...state,
            submissions: action.payload.submissions,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getSubmissionsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        updateSubmissionSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteSubmissionRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const SubmissionActions = SubmissionSlice.actions;
export const SubmissionReducer = SubmissionSlice.reducer;
