import {isSuccess, ServiceAdminApi, Where} from '@/services';
import { CommentActions } from '@/store';
import {insertObjectIfV2} from "@/utils";
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getComments(
    action: PayloadAction<{
        where?: Where;
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getComments({
            limit: pageSize,
            offset: (current - 1) * pageSize,
            include: [
                {
                    relation: 'course',
                    scope: {
                        fields: ['id', 'name'],
                    },
                },
            ],
            ...insertObjectIfV2(action?.payload?.where, {
                where: action?.payload?.where,
            }),
        });

        if (isSuccess(res)) {
            yield put(
                CommentActions.getCommentsSuccess({
                    comments: res?.data?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(CommentActions.getCommentsFailure())]);
        }
    } catch (error) {
        yield all([put(CommentActions.getCommentsFailure())]);
    }
}

export function* handleChangeCommentPagination(
    action: PayloadAction<{ pageSize: number; current: number; where?: Where; }>
): any {
    const { pageSize, current } = action.payload;

    const res = yield call(ServiceAdminApi.getComments, {
        limit: pageSize,
        offset: (current - 1) * pageSize,
        include: [
            {
                relation: 'course',
                scope: {
                    fields: ['id', 'name'],
                },
            },
        ],
        ...insertObjectIfV2(action?.payload?.where, {
            where: action?.payload?.where,
        }),
    });

    if (isSuccess(res)) {
        yield put(
            CommentActions.getCommentsSuccess({
                comments: res?.data?.data,
                total: res?.data?.count,
            })
        );
    } else {
        yield all([put(CommentActions.getCommentsFailure())]);
    }
}

export function* handleDeleteComment(action: PayloadAction<{ commentId: number; where?: Where }>): any {
    const res = yield call(ServiceAdminApi.deleteComment, action.payload.commentId);

    if (isSuccess(res)) {
        yield put(CommentActions.getCommentsRequest({
            current: 1,
            pageSize: 10,
            ...insertObjectIfV2(action?.payload?.where, {
                where: action?.payload?.where,
            }),
        }));
    } else {
        yield all([put(CommentActions.getCommentsFailure())]);
    }
}
