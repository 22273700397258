import { isSuccess, ServiceAdminApi } from '@/services';
import { ProgramActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getPrograms(action: PayloadAction<number>): any {
	try {
		const courseId = action.payload;
		const res = yield ServiceAdminApi.getPrograms(courseId, {
			order: ['index ASC', 'id ASC'],
			include: [
				{
					relation: 'lessons',
				},
			],
		});

		if (isSuccess(res)) {
			yield put(
				ProgramActions.getProgramsSuccess({
					programs: res?.data,
				})
			);
		} else {
			yield all([put(ProgramActions.getProgramsFailure())]);
		}
	} catch (error) {
		yield all([put(ProgramActions.getProgramsFailure())]);
	}
}

export function* handleDeleteProgram(
	action: PayloadAction<{ programId: number; courseId: number }>
): any {
	const res = yield call(
		ServiceAdminApi.deleteProgram,
		action.payload.programId
	);

	if (isSuccess(res)) {
		yield put(ProgramActions.getProgramsRequest(action.payload.courseId));
	} else {
		yield all([put(ProgramActions.getProgramsFailure())]);
	}
}
