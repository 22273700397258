import { NextRouter } from 'next/router';

import { ROUTERS } from '@/constant';
import { ServiceAdminApi, setAdminApiAuthorization } from '@/services';
import { deleteCookieAfterLogout, isSuccess, setAuthCookie } from '@/utils';
import { PayloadAction } from '@reduxjs/toolkit';

import { ProfileActions } from '../Profile';
import { AuthActions } from './Auth.redux';

import { message } from 'antd';
import { put } from 'redux-saga/effects';

export function* logout(): any {
	yield put(ProfileActions.resetProfile());
	deleteCookieAfterLogout();
}
export function* loginRequest(
	action: PayloadAction<{
		email: string;
		password: string;
		router: NextRouter;
	}>
): any {
	try {
		const res = yield ServiceAdminApi.login(action.payload);
		if (isSuccess(res)) {
			setAdminApiAuthorization(res.data.token);
			setAuthCookie({ token: res.data.token });
			yield put(AuthActions.loginSuccess(res.data?.token));
			action.payload.router.replace(ROUTERS.ADMIN_USER);
		} else {
			message.error('Email hoặc mật khẩu không đúng');
			yield put(AuthActions.loginFailure());
		}
	} catch (e: any) {
		message.error(e?.message);
		yield put(AuthActions.loginFailure());
	}
}
