import { isSuccess, ServiceAdminApi } from '@/services';
import { EnrollmentActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getEnrollments(
    action: PayloadAction<{
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getEnrollments({
            limit: pageSize,
            offset: (current - 1) * pageSize,
            include: [
                {
                    relation: 'course',
                },
                {
                    relation: 'user',
                },
            ],
            order: ['id DESC'],
            // where: {
            //     branchId: 1, // 1 is code4change, other id is other project
            // },
        });

        if (isSuccess(res)) {
            yield put(
                EnrollmentActions.getEnrollmentsSuccess({
                    enrollments: res?.data?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(EnrollmentActions.getEnrollmentsFailure())]);
        }
    } catch (error) {
        yield all([put(EnrollmentActions.getEnrollmentsFailure())]);
    }
}

export function* handleChangeEnrollmentPagination(
    action: PayloadAction<{ pageSize: number; current: number }>
): any {
    const { pageSize, current } = action.payload;

    const res = yield call(ServiceAdminApi.getEnrollments, {
        limit: pageSize,
        offset: (current - 1) * pageSize,
        order: ['id DESC'],
        // where: {
        //     branchId: 1, // 1 is code4change, other id is other project
        // },
    });

    if (isSuccess(res)) {
        yield put(
            EnrollmentActions.getEnrollmentsSuccess({
                enrollments: res?.data?.data,
                total: res?.data?.count,
            })
        );
    } else {
        yield all([put(EnrollmentActions.getEnrollmentsFailure())]);
    }
}

export function* handleDeleteEnrollment(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteEnrollment, action.payload);

    if (isSuccess(res)) {
        yield put(EnrollmentActions.getEnrollmentsRequest({
            current: 1,
            pageSize: 10,
        }));
    } else {
        yield all([put(EnrollmentActions.getEnrollmentsFailure())]);
    }
}
