import {ICourse, Where} from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: ICourseState = {
    fetching: false,
    courses: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type ICourseState = {
    fetching: boolean;
    courses: ICourse[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const CourseSelectors = {
    selectCourse: (state: RootState) => state.course,
    selectPagination: (state: RootState) => state.course.pagination,
};

const CourseSlice = createSlice({
    initialState,
    name: 'Course',
    reducers: {
        getCoursesRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
            where?: Where<ICourse>;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getCoursesSuccess: (
            state,
            action: PayloadAction<{ courses: ICourse[]; total: number }>
        ) => ({
            ...state,
            courses: action.payload.courses,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getCoursesFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeCoursePagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number; where?: Where<ICourse>; }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateCourseSuccess: (state, action: PayloadAction<{ where?: Where; }>) => ({
            ...state,
            fetching: true,
        }),
        deleteCourseRequest: (state, action: PayloadAction<{ courseId: number; where?: Where; }>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const CourseActions = CourseSlice.actions;
export const CourseReducer = CourseSlice.reducer;
