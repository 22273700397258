import { IUser, Where } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IUserState = {
	fetching: false,
	users: [],
	pagination: {
		current: 1, // offset = current - 1
		pageSize: 10, // limit = pageSize
		total: 0, // return from api
	},
};
type IUserState = {
	fetching: boolean;
	users: IUser[];
	pagination: {
		current: number;
		total: number;
		pageSize: number;
	};
};
export const UserSelectors = {
	selectUsers: (state: RootState) => state.user,
	selectPagination: (state: RootState) => state.user.pagination,
};

const UserSlice = createSlice({
	initialState,
	name: 'User',
	reducers: {
		getUsersRequest: (
			state,
			action: PayloadAction<{
				pageSize?: number;
				current?: number;
				where?: Where<IUser>;
			}>
		) => ({
			...state,
			pageSize: action.payload.pageSize,
			current: action.payload.current,
			fetching: true,
		}),
		getUsersSuccess: (
			state,
			action: PayloadAction<{ users: IUser[]; total: number }>
		) => ({
			...state,
			users: action.payload.users,
			fetching: false,
			pagination: {
				...state.pagination,
				total: action.payload.total,
			},
		}),
		getUsersFailure: (state) => ({
			...state,
			fetching: false,
		}),
		changePagination: (
			state,
			action: PayloadAction<{
				pageSize: number;
				current: number;
				where?: Where<IUser>;
			}>
		) => ({
			...state,
			pagination: {
				...state.pagination,
				...action.payload,
			},
		}),
		updateUserSuccess: (state) => ({
			...state,
			fetching: true,
		}),
		deleteUserRequest: (
			state,
			action: PayloadAction<{ userId: number; where?: Where }>
		) => ({
			...state,
			fetching: true,
		}),
	},
});

export const UserActions = UserSlice.actions;
export const UserReducer = UserSlice.reducer;
