import 'moment/locale/vi'; // without this line it didn't work
import moment, { Duration } from 'moment';

moment.locale('vi');

export const formatCurrency = (n: number | string, format?: string) => {
	return new Intl.NumberFormat('en-US').format(Number(n));
};

export const formatTableDate = (createdAt: string) => {
	return moment(createdAt).format('YYYY-MM-DD hh:mm');
};
export const formatTimeComment = (createdAt: string | any) => {
	return moment(createdAt).format('HH:MM, DD-MM-YYYY');
};

export const formatTimeDay = (createdAt: string | any) => {
	return moment(createdAt).format('DD');
};
export const formatTimeMonth = (createdAt: string | any) => {
	return moment(createdAt).format('MMMM');
};

export const formatFromNow = (newDate: any) => {
	return moment(newDate).fromNow();
};
export const formatTableDate2 = (createdAt: string) => {
	return moment(createdAt).format('MMM DD, YYYY');
};
export const formatDate = (createdAt: string) => {
	return moment(createdAt).format('dddd, MMMM Do YYYY');
};
export const formatDate2 = (createdAt: string) => {
	return moment(createdAt).format('MMMM Do YYYY');
};
export const formatTimeGuest = (date: string) =>
	moment(date).format('MMMM D, YYYY');

export const timeHasPassed = (date: Date | string): string | undefined => {
	if (!date) return;
	const duration: Duration = moment.duration(moment().diff(moment(date)));
	let result = '';
	switch (true) {
		case duration.years() !== 0:
			result =
				duration.years() === 1
					? `${duration.years()} Năm trước`
					: `${duration.years()} Năm trước`;
			break;
		case duration.months() !== 0:
			result =
				duration.months() === 1
					? `${duration.months()} Tháng trước`
					: `${duration.months()}  Tháng trước`;
			break;
		case duration.days() !== 0:
			result = `${duration.days()} Ngày trước`;
			break;
		case duration.hours() !== 0:
			result =
				duration.days() === 1
					? `${duration.hours()} Giờ trước`
					: `${duration.hours()} Giờ trước`;
			break;
		case duration.minutes() !== 0:
			result =
				duration.minutes() === 1
					? `${duration.minutes()} Phút trước`
					: `${duration.minutes()} Phút trước`;
			break;
		case duration.seconds() !== 0:
			result = `Vài giây trước`;
			break;
		default:
			result = `Vừa xong`;
			break;
	}
	return result;
};

export const getShortName = (firstName: string, lastName: string) => {
	if (firstName && lastName)
		return (
			String(`${firstName || ''}`)
				.trim()
				.substring(0, 1)
				.toUpperCase() +
			String(`${lastName || ''}`)
				.trim()
				.substring(0, 1)
				.toUpperCase()
		);
	else
		return String(`${firstName || ''}${lastName || ''}`)
			.trim()
			.substring(0, 2)
			.toUpperCase();
};
