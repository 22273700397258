import { IBlog, Where } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IBlogState = {
	fetching: false,
	blogs: [],
	total: 0,
	pagination: {
		current: 1, // offset = current - 1
		pageSize: 10, // limit = pageSize
		total: 0, // return from api
	},
};
type IBlogState = {
	fetching: boolean;
	blogs: IBlog[];
	total: number;
	pagination: {
		current: number;
		total: number;
		pageSize: number;
	};
};
export const BlogSelectors = {
	selectBlog: (state: RootState) => state.blog,
	selectPagination: (state: RootState) => state.blog.pagination,
};

const BlogSlice = createSlice({
	initialState,
	name: 'Blog',
	reducers: {
		getBlogsRequest: (
			state,
			action: PayloadAction<{
				pageSize?: number;
				current?: number;
				where?: Where<IBlog>;
			}>
		) => ({
			...state,
			pagination: {
				...state.pagination,
				pageSize: action?.payload.pageSize || 10,
				current: action?.payload.current || 1,
			},
			fetching: true,
		}),
		getBlogsSuccess: (
			state,
			action: PayloadAction<{ blogs: IBlog[]; total: number }>
		) => ({
			...state,
			blogs: action.payload.blogs,
			fetching: false,
			pagination: {
				...state.pagination,
				total: action.payload.total,
			},
		}),
		getBlogsFailure: (state) => ({
			...state,
			fetching: false,
		}),
		deleteBlogRequest: (
			state,
			action: PayloadAction<{ blogId: number; where?: Where }>
		) => ({
			...state,
			fetching: true,
		}),
	},
});

export const BlogActions = BlogSlice.actions;
export const BlogReducer = BlogSlice.reducer;
