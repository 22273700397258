import { IChoice } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { pathOr } from 'ramda';

const initialState: IChoiceState = {
    choices: {},
};
type IChoiceState = {
    choices:
        | {
        [key: number]: {
            choices: IChoice[];
            total: number;
            fetching: boolean;
        };
    }
        | {};
};
export const ChoiceSelectors = {
    selectChoice: (questionId: number) => (state: RootState) =>
        pathOr({}, ['choice', 'choices', questionId], state),
};

const ChoiceSlice = createSlice({
    initialState,
    name: 'Choice',
    reducers: {
        getChoicesRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            choices: {
                ...state.choices,
                [action.payload]: {
                    ...pathOr({}, ['choices', action.payload], state),
                    fetching: false,
                },
            },
            fetching: true,
        }),
        getChoicesSuccess: (
            state,
            action: PayloadAction<{
                choices: IChoice[];
                questionId: number;
            }>
        ) => ({
            ...state,
            choices: {
                ...state.choices,
                [action.payload.questionId]: {
                    choices: action.payload.choices,
                    total: action.payload.choices?.length,
                    fetching: false,
                },
            },
        }),
        getChoicesFailure: (state, action: PayloadAction<number>) => ({
            ...state,
            choices: {
                ...state.choices,
                [action.payload]: {
                    ...pathOr({}, ['choices', action.payload], state),
                    fetching: false,
                },
            },
            fetching: false,
        }),
        updateChoiceSuccess: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
        deleteChoiceRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const ChoiceActions = ChoiceSlice.actions;
export const ChoiceReducer = ChoiceSlice.reducer;
