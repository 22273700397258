import { IProduct } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IProductState = {
    fetching: false,
    products: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type IProductState = {
    fetching: boolean;
    products: IProduct[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const ProductSelectors = {
    selectProduct: (state: RootState) => state.product,
    selectPagination: (state: RootState) => state.product.pagination,
};

const ProductSlice = createSlice({
    initialState,
    name: 'Product',
    reducers: {
        getProductsRequest: (state, action: PayloadAction<{
            pageSize?: number;
            current?: number;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getProductsSuccess: (
            state,
            action: PayloadAction<{ products: IProduct[]; total: number }>
        ) => ({
            ...state,
            products: action.payload.products,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getProductsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeProductPagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateProductSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteProductRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const ProductActions = ProductSlice.actions;
export const ProductReducer = ProductSlice.reducer;
