import { IProject } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IProjectState = {
	fetching: false,
	projects: [],
	total: 0,
	pagination: {
		current: 1, // offset = current - 1
		pageSize: 10, // limit = pageSize
		total: 0, // return from api
	},
};
type IProjectState = {
	fetching: boolean;
	projects: IProject[];
	total: number;
	pagination: {
		current: number;
		total: number;
		pageSize: number;
	};
};
export const ProjectSelectors = {
	selectProject: (state: RootState) => state.project,
	selectPagination: (state: RootState) => state.project.pagination,
};

const ProjectSlice = createSlice({
	initialState,
	name: 'Project',
	reducers: {
		getProjectsRequest: (
			state,
			action: PayloadAction<{
				pageSize?: number;
				current?: number;
			}>
		) => ({
			...state,
			pageSize: action?.payload.pageSize || 10,
			current: action?.payload.current || 1,
			fetching: true,
		}),
		getProjectsSuccess: (
			state,
			action: PayloadAction<{ projects: IProject[]; total: number }>
		) => ({
			...state,
			projects: action?.payload.projects,
			fetching: false,
			pagination: {
				...state.pagination,
				total: action?.payload.total,
			},
		}),
		getProjectsFailure: (state) => ({
			...state,
			fetching: false,
		}),
		changeProjectPagination: (
			state,
			action: PayloadAction<{ pageSize: number; current: number }>
		) => ({
			...state,
			pagination: {
				...state.pagination,
				...action.payload,
			},
		}),
		deleteProjectRequest: (state, action: PayloadAction<number>) => ({
			...state,
			fetching: true,
		}),
	},
});

export const ProjectActions = ProjectSlice.actions;
export const ProjectReducer = ProjectSlice.reducer;
