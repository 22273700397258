import { isSuccess, ServiceAdminApi } from '@/services';
import {ChoiceActions} from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getChoices(action: PayloadAction<number>): any {
    const questionId = action.payload;

    try {
        const res = yield ServiceAdminApi.getChoices({
            where: {
                questionId,
            },
            order: ['id ASC'],
        });

        if (isSuccess(res)) {
            yield put(
                ChoiceActions.getChoicesSuccess({
                    choices: res?.data,
                    questionId,
                })
            );
        } else {
            yield all([put(ChoiceActions.getChoicesFailure(questionId))]);
        }
    } catch (error) {
        yield all([put(ChoiceActions.getChoicesFailure(questionId))]);
    }
}

export function* handleDeleteChoice(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteChoice, action.payload);
    const questionId = action.payload;

    if (isSuccess(res)) {
        yield put(ChoiceActions.getChoicesRequest(questionId));
    } else {
        yield all([put(ChoiceActions.getChoicesFailure(questionId))]);
    }
}
