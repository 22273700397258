import {isSuccess, ServiceAdminApi} from '@/services';
import {MediaContentActions} from '@/store';
import {PayloadAction} from '@reduxjs/toolkit';

import {all, call, put} from 'redux-saga/effects';

export function* getMediaContents(
    action: PayloadAction<{
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getMediaContents({
            limit: pageSize,
            offset: (current - 1) * pageSize,
            order: ['id DESC'],
        });

        if (isSuccess(res)) {
            yield put(
                MediaContentActions.getMediaContentsSuccess({
                    mediaContents: res?.data?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(MediaContentActions.getMediaContentsFailure())]);
        }
    } catch (error) {
        yield all([put(MediaContentActions.getMediaContentsFailure())]);
    }
}

export function* handleChangeMediaContentPagination(
    action: PayloadAction<{ pageSize: number; current: number }>
): any {
    const { pageSize, current } = action.payload;

    const res = yield call(ServiceAdminApi.getMediaContents, {
        limit: pageSize,
        offset: (current - 1) * pageSize,
        order: ['id DESC'],
    });

    if (isSuccess(res)) {
        yield put(
            MediaContentActions.getMediaContentsSuccess({
                mediaContents: res?.data?.data,
                total: res?.data?.count,
            })
        );
    } else {
        yield all([put(MediaContentActions.getMediaContentsFailure())]);
    }
}

export function* handleDeleteMediaContent(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteMediaContent, action.payload);
    if (isSuccess(res)) {
        yield put(MediaContentActions.getMediaContentsRequest({
            current: 1,
            pageSize: 10,
        }));
    } else {
        yield all([put(MediaContentActions.getMediaContentsFailure())]);
    }
}
