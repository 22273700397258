import { IMedia } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IMediaContentState = {
    fetching: false,
    mediaContents: [],
    total: 0,
    pagination: {
        current: 1, // offset = current - 1
        pageSize: 10, // limit = pageSize
        total: 0, // return from api
    },
};
type IMediaContentState = {
    fetching: boolean;
    mediaContents: IMedia[];
    total: number;
    pagination: {
        current: number;
        total: number;
        pageSize: number;
    };
};
export const MediaContentSelectors = {
    selectMediaContent: (state: RootState) => state.mediaContent,
    selectPagination: (state: RootState) => state.mediaContent.pagination,
};

const MediaContentSlice = createSlice({
    initialState,
    name: 'MediaContent',
    reducers: {
        getMediaContentsRequest: (state, action: PayloadAction<{
            pageSize: number;
            current: number;
        }>) => ({
            ...state,
            pageSize: action.payload.pageSize,
            current: action.payload.current,
            fetching: true,
        }),
        getMediaContentsSuccess: (
            state,
            action: PayloadAction<{ mediaContents: IMedia[]; total: number }>
        ) => ({
            ...state,
            mediaContents: action.payload.mediaContents,
            fetching: false,
            pagination: {
                ...state.pagination,
                total: action.payload.total,
            },
        }),
        getMediaContentsFailure: (state) => ({
            ...state,
            fetching: false,
        }),
        changeMediaContentPagination: (
            state,
            action: PayloadAction<{ pageSize: number; current: number }>
        ) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...action.payload,
            },
        }),
        updateMediaContentSuccess: (state) => ({
            ...state,
            fetching: true,
        }),
        deleteMediaContentRequest: (state, action: PayloadAction<number>) => ({
            ...state,
            fetching: true,
        }),
    },
});

export const MediaContentActions = MediaContentSlice.actions;
export const MediaContentReducer = MediaContentSlice.reducer;
