import { isSuccess, ServiceAdminApi, Where } from '@/services';
import { insertObjectIfV2 } from '@/utils';
import { PayloadAction } from '@reduxjs/toolkit';

import { UserActions } from './User.redux';

import { all, call, put } from 'redux-saga/effects';

export function* getUsers(
	action: PayloadAction<{
		where?: Where;
		pageSize: number;
		current: number;
	}>
): any {
	try {
		const { pageSize, current } = action.payload;
		const res = yield ServiceAdminApi.getUsers({
			limit: pageSize,
			offset: (current - 1) * pageSize,
			order: ['id DESC'],
			...insertObjectIfV2(action?.payload?.where, {
				where: action?.payload?.where,
			}),
		});

		if (isSuccess(res)) {
			yield put(
				UserActions.getUsersSuccess({
					users: res?.data?.data,
					total: res?.data?.count,
				})
			);
		} else {
			yield all([put(UserActions.getUsersFailure())]);
		}
	} catch (error) {
		yield all([put(UserActions.getUsersFailure())]);
	}
}

export function* handleChangePagination(
	action: PayloadAction<{ pageSize: number; current: number; where?: Where }>
): any {
	const { pageSize, current } = action.payload;

	const res = yield call(ServiceAdminApi.getUsers, {
		limit: pageSize,
		offset: (current - 1) * pageSize,
		order: ['id DESC'],
		...insertObjectIfV2(action?.payload?.where, {
			where: action?.payload?.where,
		}),
	});

	if (isSuccess(res)) {
		yield put(
			UserActions.getUsersSuccess({
				users: res?.data?.data,
				total: res?.data?.count,
			})
		);
	} else {
		yield all([put(UserActions.getUsersFailure())]);
	}
}

export function* handleDeleteUser(
	action: PayloadAction<{ userId: number; where?: Where }>
): any {
	const res = yield call(ServiceAdminApi.deleteUser, action.payload.userId);

	if (isSuccess(res)) {
		yield put(
			UserActions.getUsersRequest({
				current: 1,
				pageSize: 10,
				...insertObjectIfV2(action?.payload?.where, {
					where: action?.payload?.where,
				}),
			})
		);
	} else {
		yield all([put(UserActions.getUsersFailure())]);
	}
}
