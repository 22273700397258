import { isSuccess, ServiceAdminApi } from '@/services';
import { ProductActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getProducts(
    action: PayloadAction<{
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getProducts({
            limit: pageSize,
            offset: (current - 1) * pageSize,
            order: ['id DESC'],
            include: [
                {
                    relation: 'mediaContents',
                },
            ],
            where: {
                branchId: 1, // 1 is code4change, other id is other product
            },
        });

        if (isSuccess(res)) {
            yield put(
                ProductActions.getProductsSuccess({
                    products: res?.data?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(ProductActions.getProductsFailure())]);
        }
    } catch (error) {
        yield all([put(ProductActions.getProductsFailure())]);
    }
}

export function* handleChangeProductPagination(
    action: PayloadAction<{ pageSize: number; current: number }>
): any {
    const { pageSize, current } = action.payload;

    const res = yield call(ServiceAdminApi.getProducts, {
        limit: pageSize,
        offset: (current - 1) * pageSize,
        order: ['id DESC'],
        include: [
            {
                relation: 'mediaContents',
            },
        ],
        where: {
            branchId: 1, // 1 is code4change, other id is other product
        },
    });

    if (isSuccess(res)) {
        yield put(
            ProductActions.getProductsSuccess({
                products: res?.data?.data,
                total: res?.data?.count,
            })
        );
    } else {
        yield all([put(ProductActions.getProductsFailure())]);
    }
}

export function* handleDeleteProduct(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteProduct, action.payload);

    if (isSuccess(res)) {
        yield put(ProductActions.getProductsRequest({
            current: 1,
            pageSize: 10,
        }));
    } else {
        yield all([put(ProductActions.getProductsFailure())]);
    }
}
