import { IProgram } from '@/services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

const initialState: IProgramState = {
	fetching: false,
	programs: [],
	total: 0,
};
type IProgramState = {
	fetching: boolean;
	programs: IProgram[];
	total: number;
};
export const ProgramSelectors = {
	selectProgram: (state: RootState) => state.program,
};

const ProgramSlice = createSlice({
	initialState,
	name: 'Program',
	reducers: {
		getProgramsRequest: (state, action: PayloadAction<number>) => ({
			...state,
			fetching: true,
		}),
		getProgramsSuccess: (
			state,
			action: PayloadAction<{ programs: IProgram[] }>
		) => ({
			...state,
			programs: action.payload.programs,
			total: action.payload.programs?.length,
			fetching: false,
		}),
		getProgramsFailure: (state) => ({
			...state,
			fetching: false,
		}),
		updateProgramSuccess: (state, action: PayloadAction<number>) => ({
			...state,
			fetching: true,
		}),
		deleteProgramRequest: (
			state,
			action: PayloadAction<{ programId: number; courseId: number }>
		) => ({
			...state,
			fetching: true,
		}),
	},
});

export const ProgramActions = ProgramSlice.actions;
export const ProgramReducer = ProgramSlice.reducer;
