import { isSuccess, ServiceAdminApi } from '@/services';
import { SubmissionActions } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';

import { all, call, put } from 'redux-saga/effects';

export function* getSubmissions(
    action: PayloadAction<{
        pageSize: number;
        current: number;
    }>
): any {
    try {
        const { pageSize, current } = action.payload;
        const res = yield ServiceAdminApi.getSubmissions({
            limit: pageSize,
            offset: (current - 1) * pageSize,
            order: ['id DESC'],
            include: [
                {
                    relation: 'course',
                    scope: {
                        fields: ['id', 'name']
                    }
                },
                {
                    relation: 'user',
                    scope: {
                        fields: ['id', 'firstName', 'lastName']
                    }
                }
            ]
        });

        if (isSuccess(res)) {
            yield put(
                SubmissionActions.getSubmissionsSuccess({
                    submissions: res?.data,
                    total: res?.data?.count,
                })
            );
        } else {
            yield all([put(SubmissionActions.getSubmissionsFailure())]);
        }
    } catch (error) {
        yield all([put(SubmissionActions.getSubmissionsFailure())]);
    }
}

export function* handleDeleteSubmission(action: PayloadAction<number>): any {
    const res = yield call(ServiceAdminApi.deleteSubmission, action.payload);

    if (isSuccess(res)) {
        yield put(SubmissionActions.getSubmissionsRequest({
            current: 1,
            pageSize: 10,
        }));
    } else {
        yield all([put(SubmissionActions.getSubmissionsFailure())]);
    }
}
