export enum RESOURCE_TYPE {
	VIDEO = 'VIDEO',
	IMAGE = 'IMAGE',
}

export const TYPE_IMAGE = {
	BLOG: 'BLOG',
	PROJECT: 'PROJECT',
	REVIEW: 'REVIEW',
	COURSE: 'COURSE',
	PRODUCT: 'PRODUCT',
};

export const TYPE_COMMENT = {
	BLOG: 'BLOG',
	COURSE: 'COURSE',
};

export const METADATA = {};

export const ORDERS = [
	{ label: 'sort.propose', value: 'index ASC' },
	{ label: 'sort.new', value: 'id DESC' },
	{ label: 'sort.view', value: 'view DESC' },
];

export const avatarUrl =
	'https://code4change.dev/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fteam4.e3d2ee86.jpeg&w=3840&q=75';
